<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <div :class="$style.phone">
        <div :class="$style.head">
          <img :src="oss + '/manage/media/assets/statusbar_b.png'" />
        </div>
        <div :class="$style.title">
          <p>兴趣社区</p>
        </div>
        <div :class="$style.content">
          <div v-if="data.channel_style === 1" :class="$style.nav">
            <div v-for="item in 5" :key="item" :class="$style.navBox">
              <div :class="$style.block" />
              <div>频道名称</div>
            </div>
          </div>
          <div v-if="data.channel_style === 2" :class="$style.waterfallnav">
            <div v-for="item in 5" :key="item" :class="$style.waterfallnavBox">
              <div v-if="item === 1" :class="$style.active">
                推荐
                <div :class="$style.dot" />
              </div>
              <div v-else>频道名称</div>
            </div>
          </div>

          <div v-if="data.post_style === 1" :class="$style.container">
            <div v-for="item in 4" :key="item" :class="$style.containerBox">
              <div :class="$style.one" />
              <div :class="$style.two" />
              <div :class="$style.three" />
              <div :class="$style.four" />
              <div :class="$style.five" />
            </div>
          </div>
        </div>
        <div :class="$style.waterfall">
          <div v-if="data.post_style === 2">
            <div :class="$style.small">
              <div :class="$style.one" />
              <div :class="$style.two" />
              <div :class="$style.three" />
              <div :class="$style.four" />
              <div :class="$style.five" />
              <!-- <div :class="$style.six" /> -->
              <div :class="$style.sbox">
                <div v-for="item in 4" :key="item" :class="$style.item" />
              </div>
            </div>
            <div :class="$style.big">
              <div :class="$style.one" />
              <div :class="$style.two" />
              <div :class="$style.three" />
              <div :class="$style.four" />
              <div :class="$style.five" />
              <div :class="$style.six" />
              <div :class="$style.sbox">
                <div v-for="item in 4" :key="item" :class="$style.item" />
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.foot">
          <img :src="oss + '/manage/media/assets/homebar.png'" />
        </div>
      </div>
    </div>
    <div :class="$style.right">
      <p>基础信息</p>
      <!-- —————————————— -->
      <div :class="$style.box">
        <div :class="$style.boxLeft">
          <span class="required">*</span>
          社区名称
        </div>
        <div :class="$style.boxRight">
          <BjInput v-model="data.name" placeholder="请输入社区名称" />
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.boxLeft">
          <span class="required">*</span>
          频道风格
        </div>
        <div :class="$style.boxRight" class="line-40">
          <a-radio-group v-model="data.channel_style">
            <a-radio :value="1"> 贴片 </a-radio>
            <a-radio :value="2"> 文字 </a-radio>
          </a-radio-group>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.boxLeft">
          <span class="required">*</span>
          帖子风格
        </div>
        <div :class="$style.boxRight" class="line-40">
          <a-radio-group v-model="data.post_style">
            <a-radio :value="1"> 瀑布流风格 </a-radio>
            <a-radio :value="2"> 论坛风格 </a-radio>
          </a-radio-group>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.boxLeft">
          <span class="required">*</span>
          帖子审核
        </div>
        <div :class="$style.boxRight">
          <!-- <BjSwitch v-model="data.post_examine" size="default" /> -->
          <a-radio-group v-model="data.post_ugc_audit_type" name="radioGroup">
            <a-radio :value="0">
              智能审核
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>由人工智能机器审核</span>
                </template>
                <i :class="$style.question" class="ri-question-line" />
              </a-tooltip>
            </a-radio>
            <a-radio :value="1"> 人工审核 </a-radio>
          </a-radio-group>
          <div class="tip mt-10">帖子审核通过后会自动发布，否则需要人工审核后才能发布。</div>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.boxLeft">
          <span class="required">*</span>
          允许评论
        </div>
        <div :class="$style.boxRight">
          <BjSwitch v-model="data.post_examine" size="default" />
          <div class="tip mt-10">如果开启审核，用户发布帖子后需要管理员后台审核后才能发布成功，向所有用户展示。</div>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.boxLeft">
          <span class="required">*</span>
          评论审核
        </div>
        <div :class="$style.boxRight">
          <!-- <BjSwitch v-model="data.post_examine" size="default" /> -->
          <a-radio-group v-model="data.comment_ugc_audit_type" name="radioGroup">
            <a-radio :value="0">
              智能审核
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>由人工智能机器审核</span>
                </template>
                <i :class="$style.question" class="ri-question-line" />
              </a-tooltip>
            </a-radio>
            <a-radio :value="1"> 人工审核 </a-radio>
          </a-radio-group>
          <div class="tip mt-10">帖子审核通过后会自动发布，否则需要人工审核后才能发布。</div>
        </div>
      </div>

      <!-- —————————————— -->
      <div :class="$style.footer">
        <BjButton v-permission="'app.community.setting-edit'" class="mr-10" type="primary" @click="onSave()">
          <i class="ri-save-line left" />
          立即保存
        </BjButton>
      </div>
    </div>
  </div>
</template>

<script>
import { communityService } from '@/service'

const service = new communityService()

export default {
  name: 'Home',
  data() {
    return {
      data: {
        name: null,
        channel_style: 1,
        post_style: 1,
        post_ugc_audit_type: 0,
        comment_ugc_audit_type: 0,
      },
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getSetting()
        this.data = data
      } catch (e) {}
    },
    async onSave() {
      try {
        await service.saveSetting(this.data)
        this.$message.success('保存成功')
      } catch (e) {
        this.getInfo()
      }
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-switch {
      margin-top: 8px;
    }
  }

  .left {
    width: 390px;
    height: 750px;
    margin-right: 20px;

    .phone {
      position: relative;
      width: 390px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

      .head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 44px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .foot {
        position: absolute;
        bottom: 0;
        background: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        width: 100%;
        height: 88px;
        color: #000;
        font-size: 16px;
        line-height: 132px;
        text-align: center;
        background-color: #fff;
      }

      .content {
        .nav {
          display: flex;
          justify-content: space-between;
          width: 430px;
          padding: 20px 20px 0 20px;

          &-box {
            width: 70px;
            height: 81px;
            padding: 10px;
            font-size: 12px;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;

            .block {
              width: 36px;
              height: 36px;
              margin: 0 auto;
              margin-bottom: 10px;
              background-color: #fafafa;
              border-radius: 100%;
            }
          }

          &-box:nth-child(1) {
            color: #fff;
            background-color: @primary-color;
          }
        }

        .waterfallnav {
          display: flex;
          width: 120%;
          padding: 14px 10px 5px;
          background-color: #fff;

          .active {
            color: @primary-color;
          }

          .dot {
            width: 5px;
            height: 5px;
            margin: 0 auto;
            background-color: @primary-color;
            border-radius: 100%;
          }

          &-box {
            margin: 0 10px;
            color: #5c5c5c;
            font-size: 16px;
          }
        }

        .container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 20px 20px 0;

          &-box {
            width: 170px;
            height: 240px;
            margin-bottom: 10px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 6px;

            .one {
              width: 170px;
              height: 170px;
              background-color: #eee;
            }

            .two {
              width: 150px;
              height: 20px;
              margin: 10px auto;
              background-color: #eee;
            }

            .three {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin: 0 6px 0 10px;
              background-color: #eee;
              border-radius: 100%;
            }

            .four {
              display: inline-block;
              width: 50px;
              height: 20px;
              margin-right: 54px;
              background-color: #eee;
            }

            .five {
              display: inline-block;
              width: 20px;
              height: 20px;
              background-color: #eee;
            }
          }
        }
      }

      .waterfall {
        .small {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 20px 20px 10px 20px;
          padding: 14px;
          background-color: #fff;

          .one {
            width: 28px;
            height: 28px;
            margin-right: 10px;
            background-color: #eee;
            border-radius: 100%;
          }

          .two {
            width: 80px;
            height: 20px;
            margin-right: 144px;
            background-color: #eee;
          }

          .three {
            width: 60px;
            height: 20px;
            background-color: #eee;
          }

          .four {
            width: 100%;
            height: 20px;
            margin-top: 14px;
            background-color: #eee;
          }

          .five {
            width: 87px;
            height: 20px;
            margin-top: 4px;
            background-color: #eee;
          }

          .sbox {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 14px;

            .item {
              width: 50px;
              height: 20px;
              background-color: #eee;
            }
          }
        }

        .big {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 0 20px 20px 20px;
          padding: 14px;
          background-color: #fff;

          .one {
            width: 28px;
            height: 28px;
            margin-right: 10px;
            background-color: #eee;
            border-radius: 100%;
          }

          .two {
            width: 80px;
            height: 20px;
            margin-right: 144px;
            background-color: #eee;
          }

          .three {
            width: 60px;
            height: 20px;
            background-color: #eee;
          }

          .four {
            width: 100%;
            height: 140px;
            margin-top: 14px;
            background-color: #eee;
          }

          .five {
            width: 100%;
            height: 20px;
            margin-top: 10px;
            background-color: #eee;
          }

          .six {
            width: 87px;
            height: 20px;
            margin-top: 4px;
            background-color: #eee;
          }

          .sbox {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 14px;

            .item {
              width: 50px;
              height: 20px;
              background-color: #eee;
            }
          }
        }
      }
    }
  }

  .right {
    position: relative;
    flex: 1;
    padding: 20px;
    background: #fff;
  }

  .box {
    display: flex;
    margin-bottom: 20px;

    &-left {
      width: 140px;
      line-height: 40px;
    }

    &-right {
      flex: 1;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding-left: 20px;
    line-height: 60px;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }
}

.question {
  font-size: 16px;
  color: #5c5c5c;
}
</style>
